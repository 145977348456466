
import { Vue, Options } from "vue-class-component";
import DeliveryPerformanceSummaryCard from "@/app/ui/views/dashboard/component/client/section-delivery-performance/delivery-performance-summary-card.vue";
import DeliveryPerformanceDetailCard from "@/app/ui/views/dashboard/component/client/section-delivery-performance/delivery-performance-detail-card.vue";
import DeliveryPerformancePerRouteCard from "@/app/ui/views/dashboard/component/client/section-delivery-performance/delivery-performance-per-route-card.vue";

@Options({
  components: {
    DeliveryPerformancePerRouteCard,
    DeliveryPerformanceDetailCard,
    DeliveryPerformanceSummaryCard
  }
})
export default class SectionDeliveryPerformanceDashboard extends Vue {}
