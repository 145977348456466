
import { Vue, Options } from "vue-class-component";
import CardDashboardClient from "@/app/ui/views/dashboard/component/client/component/card-dashboard-client.vue";
import BulletPoint from "@/app/ui/components/icons/modules/bullet-point.vue";
import {
  formatPriceWithoutCurrency,
  IS_SENDER_ACCOUNT
} from "@/app/infrastructures/misc/Utils";
import DonutChart from "@/app/ui/components/donut-chart/index.vue";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import { TRACK_DELIVERY } from "@/app/infrastructures/misc/RolePermission";

@Options({
  components: {
    BulletPoint,
    CardDashboardClient,
    DonutChart
  }
})
export default class DeliveryPerformanceSummaryCard extends Vue {
  mounted() {
    this.fetchSummaryDeliveryPerformance();
  }
  async fetchSummaryDeliveryPerformance() {
    this.isLoading = true;
    const response = await DashboardController.getCorporateDashboardDeliveryData(
      {
        path: "/performance/summary",
        sttCategory: "",
        cache: true
      }
    );
    this.dataPerformance.late = response.totalSttLate;
    this.dataPerformance.onTime = response.totalSttOnTime;
    this.isLoading = false;
  }

  formattedNumber(value: number) {
    return formatPriceWithoutCurrency(value);
  }

  // performance data
  isLoading = true;
  dataPerformance = {
    onTime: 0,
    late: 0
  };
  get chartData() {
    return [this.dataPerformance.onTime, this.dataPerformance.late];
  }
  chartColor = ["#7ACB90", "#F05C72"];

  styleOnTimePerformance: any = "";
  styleLatePerformance: any = "";
  onHover(section: string) {
    if (!this.ableToViewDetail) return;
    if (section === "late") {
      this.styleLatePerformance = {
        color: this.chartColor[1]
      };
    }
    if (section === "onTime") {
      this.styleOnTimePerformance = {
        color: this.chartColor[0]
      };
    }
  }
  onLeaveHover() {
    this.styleOnTimePerformance = "";
    this.styleLatePerformance = "";
  }
  get ableToViewDetail() {
    if (IS_SENDER_ACCOUNT) return true;
    return checkRolePermission(TRACK_DELIVERY.ENABLE);
  }
  onClickDetail(dashboardInfo: string) {
    if (!this.ableToViewDetail) return;
    DashboardController.openNewPageFromDashboardCorporate(dashboardInfo);
  }
}
