import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-16px font-semibold text-red-lp-300" }
const _hoisted_2 = {
  style: {"height":"325px"},
  class: "overflow-y-auto mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_CardDashboardClient = _resolveComponent("CardDashboardClient")!

  return (_openBlock(), _createBlock(_component_CardDashboardClient, { title: "Per Rute" }, {
    "top-right": _withCtx(() => [
      _createVNode("div", _hoisted_1, _toDisplayString(_ctx.dataPerRoutePerformance.length) + " Rute ", 1)
    ]),
    default: _withCtx(() => [
      _createVNode("div", _hoisted_2, [
        _createVNode(_component_TableV2, {
          columns: _ctx.columns,
          data: _ctx.sortedDataPerRouteTotalResi,
          borderTop: false,
          borderBottom: false,
          loading: _ctx.isLoading,
          isShowPagination: false,
          hidePagination: "",
          isNoPaddingBottom: true,
          isSpaceTop: false,
          headerTextColor: "text-black-lp-300",
          ableToHoverPerRow: "",
          hoverTemplate: _ctx.perRouteDetail
        }, null, 8, ["columns", "data", "loading", "hoverTemplate"])
      ])
    ]),
    _: 1
  }))
}