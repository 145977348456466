
import { Vue, Options } from "vue-class-component";
import CardDashboardClient from "@/app/ui/views/dashboard/component/client/component/card-dashboard-client.vue";
import BulletPoint from "@/app/ui/components/icons/modules/bullet-point.vue";
import AreaChart from "@/app/ui/components/chart/area-bar/index.vue";
import { DashboardController } from "@/app/ui/controllers/DashboardController";

@Options({
  components: {
    BulletPoint,
    CardDashboardClient,
    AreaChart
  }
})
export default class DeliveryPerformanceDetailCard extends Vue {
  async mounted() {
    await this.fetchData();
  }

  async fetchData() {
    this.chart.loading = true;
    const response = await DashboardController.getCorporateDashboardDetailAreaBarChart(
      {
        path: "/performance/detail"
      }
    );
    this.chart.loading = false;
    this.chart.series = response.performaDelivery.series;
    this.chart.categories = response.performaDelivery.categories;
  }
  chart: any = {
    loading: false,
    title: "Performa",
    series: [],
    categories: []
  };
}
