
import { Vue, Options } from "vue-class-component";
import CardDashboardClient from "@/app/ui/views/dashboard/component/client/component/card-dashboard-client.vue";
import { removeDecimalWithoutRounding } from "@/app/infrastructures/misc/Utils";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import { PerformancePerRouteData } from "@/domain/entities/Dashboard";

@Options({
  components: {
    CardDashboardClient
  }
})
export default class DeliveryPerformancePerRouteCard extends Vue {
  mounted() {
    this.getDashboardPerformancePerRoute();
  }
  formatInputFloat(value: number) {
    return removeDecimalWithoutRounding(value);
  }
  isLoading = true;

  async getDashboardPerformancePerRoute() {
    this.isLoading = true;
    const response = await DashboardController.getCorporateDashboardDeliveryData(
      {
        path: "/performance/route",
        sttCategory: "",
        cache: true
      }
    );
    this.isLoading = false;
    this.dataPerRoutePerformance = response.performancePerRouteData;
  }

  // per route performance
  sortingPerRouteTotalResi = { sortBy: "desc", orderBy: "route" };
  get isDescending() {
    return this.sortingPerRouteTotalResi.sortBy === "desc";
  }
  iconSorting(key: string) {
    return this.disableIconHeader(key) ||
      (this.isDescending && this.sortingPerRouteTotalResi.orderBy === key)
      ? "descending"
      : "ascending";
  }
  disableIconHeader(key: string) {
    return this.sortingPerRouteTotalResi.orderBy !== key;
  }
  onSorting(column: any) {
    this.sortingPerRouteTotalResi = {
      sortBy:
        this.isDescending &&
        this.sortingPerRouteTotalResi.orderBy === column?.sortingKey
          ? "asc"
          : "desc",
      orderBy: column?.sortingKey
    };
  }
  get columns() {
    return [
      {
        name: "Rute",
        styleHead: "w-28 text-white text-left whitespace-no-wrap align-top",
        columnKey: "route",
        sortingKey: "route",
        icon: this.iconSorting("route"),
        vtooltipHeader: () => null,
        disableIconHeader: (column: any) =>
          this.disableIconHeader(column?.sortingKey),
        onClickHeader: this.onSorting,
        render: (item: any) => {
          return item.routeName;
        }
      },
      {
        name: "Performa",
        styleHead: "w-28 text-white text-left whitespace-no-wrap align-top",
        columnKey: "performance",
        sortingKey: "performance",
        icon: this.iconSorting("performance"),
        vtooltipHeader: () => null,
        disableIconHeader: (column: any) =>
          this.disableIconHeader(column?.sortingKey),
        onClickHeader: this.onSorting,
        render: (item: any) => {
          return `<div class='font-semibold'>${this.formatInputFloat(
            item.performance
          )}%</div>`;
        }
      }
    ];
  }
  dataPerRoutePerformance: PerformancePerRouteData[] = [];
  sortDataByRoute(a: PerformancePerRouteData, b: PerformancePerRouteData) {
    if (this.sortingPerRouteTotalResi.sortBy === "asc") {
      if (a.routeName < b.routeName) {
        return -1;
      }
      if (a.routeName > b.routeName) {
        return 1;
      }
      return 0;
    }
    if (this.sortingPerRouteTotalResi.sortBy === "desc") {
      if (b.routeName < a.routeName) {
        return -1;
      }
      if (b.routeName > a.routeName) {
        return 1;
      }
      return 0;
    }
    return 0;
  }
  sortDataByPerformance(
    a: PerformancePerRouteData,
    b: PerformancePerRouteData
  ) {
    if (this.sortingPerRouteTotalResi.sortBy === "asc") {
      return a.performance - b.performance;
    }
    if (this.sortingPerRouteTotalResi.sortBy === "desc") {
      return b.performance - a.performance;
    }
    return 0;
  }
  get sortedDataPerRouteTotalResi() {
    return this.dataPerRoutePerformance.sort(
      (a: PerformancePerRouteData, b: PerformancePerRouteData) => {
        if (this.sortingPerRouteTotalResi.orderBy === "route") {
          return this.sortDataByRoute(a, b);
        }
        if (this.sortingPerRouteTotalResi.orderBy === "performance") {
          return this.sortDataByPerformance(a, b);
        }
        return 0;
      }
    );
  }
  perRouteDetail(item: PerformancePerRouteData) {
    let templateHtml =
      `<div class="w-40 text-10px">` +
      `<div class="text-center"><strong>${item.routeName}</strong></div>` +
      `<hr class="my-2" />` +
      `<div class="grid gap-y-1 grid-cols-2 mb-1">`;

    for (const routeProduct in item.detailPerformance) {
      templateHtml += `<div><div class="text-8px text-gray-lp-800 capitalize">${item.detailPerformance[routeProduct].productType}</div><strong>${item.detailPerformance[routeProduct].totalResi} Resi</strong></div>`;
    }

    templateHtml += `</div>` + `</div>`;
    return templateHtml;
  }
}
